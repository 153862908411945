import React from 'react';

const MemeDetails = ({ meme, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50 p-4">
    <div className="bg-white rounded-lg shadow-lg overflow-auto max-w-screen-md w-full max-h-screen">
      <button className="bg-gray-800 text-white px-4 py-2 w-full text-right" onClick={onClose}>Close</button>
      <div className="p-4">
        <div className="text-center mb-4">
          <h2 className="text-2xl font-semibold">{meme.title}</h2>
        </div>
        <div className="flex justify-center items-center mb-4">
          {meme.attachments.map((attachment, index) => (
            <img 
              key={index} 
              src={attachment.url} 
              alt={attachment.format} 
              className="max-w-full h-auto object-contain" 
              style={{ maxHeight: '70vh' }} 
            />
          ))}
        </div>
        <div className="text-center mb-4">
          <p className="text-gray-600">{meme.text || ''}</p>
        </div>
        <div className="bg-gray-100 p-4 flex justify-between text-gray-700 text-sm">
          <p>By: {meme.redditor_id || 'Anonymous'}</p>
          <p>{new Date(meme.created_at).toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  </div>
);

export default MemeDetails;
