import React from 'react';
import MemeCard from './MemeCard';

const MemeGrid = ({ memes, onMemeClick }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {memes.map((meme, index) => (
        <MemeCard
          key={meme.id || index}
          meme={meme}
          index={index}
          onMemeClick={onMemeClick}
        />
      ))}
    </div>
  );
};

export default MemeGrid;
