import React from 'react';

const ContactInfo = () => (
  <section className="p-4">
    <div className="bg-white shadow rounded-lg p-4">
      <ul className="mt-2 space-y-2">
        <li className="flex items-center">
          <img src="./email.svg" alt="Email Icon" className="w-6 h-6 mr-2" />
          <a href="mailto:everythingdatascience@gmail.com" className="text-blue-500 hover:underline">Email</a>
        </li>
        <li className="flex items-center">
          <img src="./linkedin.svg" alt="LinkedIn Icon" className="w-6 h-6 mr-2" />
          <a href="https://www.linkedin.com/in/sasha-kaun-9a8b1a1b6" className="text-blue-500 hover:underline">LinkedIn</a>
        </li>
        <li className="flex items-center">
          <img src="./github.svg" alt="GitHub Icon" className="w-6 h-6 mr-2" />
          <a href="https://github.com/sk-drop" className="text-blue-500 hover:underline">GitHub</a>
        </li>
        <li className="flex items-center">
          <img src="./telephone.svg" alt="Telephone Icon" className="w-6 h-6 mr-2" />
          <a href="tel:+4917681266991" className="text-blue-500 hover:underline">Telephone</a>
        </li>
      </ul>
    </div>
  </section>
);

export default ContactInfo;
