import React from 'react';

const GameLink = () => {
  return (
    <div>
      <a href="/game.html" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
        play this CV
      </a>
    </div>
  );
};

export default GameLink;
