// src/components/ContentNotAvailable.jsx

import React, { useState, useEffect } from 'react';
import Header from './Header';
import Navigation from './Navigation';
import { fetchData } from '../services/api';

const ContentNotAvailable = () => {
  const [randomMeme, setRandomMeme] = useState(null);

  useEffect(() => {
    const getRandomMeme = async () => {
      try {
        const memes = await fetchData('submission', 'submission_id, attachment, title, text, redditor_id, created_at', 1);
        if (memes.length > 0) {
          const randomIndex = Math.floor(Math.random() * memes.length);
          setRandomMeme(memes[randomIndex]);
        }
      } catch (error) {
        console.error('Failed to fetch memes:', error);
      }
    };

    getRandomMeme();
  }, []);

  return (
    <div>
      <Header />
      <Navigation />
      <div className="pt-16 md:flex">
        <main className="flex-1 p-4 md:ml-64 flex flex-col items-center justify-center min-h-screen bg-gray-100">
          <div className="text-center">
            <h1 className="text-3xl font-bold mb-4">Content Not Available Yet</h1>
            <p className="text-lg mb-4">We're working very hard to bring you this content.. </p>
            <p className="text-lg mb-4">We are sweating, I swear!</p>
            <p className="text-lg mb-4">In the meantime, here's a random meme</p>
            {randomMeme && (
              <div className="mb-4">
                {randomMeme.attachments.map((attachment, index) => (
                    <img key={index} src={attachment.url} alt={attachment.format} className="w-full h-full object-cover" />
                ))}
                <p>{randomMeme.text}</p>
              </div>
            )}
            <a href="/" className="bg-blue-500 text-white px-4 py-2 rounded">Go Back Home</a>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ContentNotAvailable;
