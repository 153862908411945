import React from 'react';

const SearchBar = ({ query, onChange }) => (
  <input
    type="text"
    placeholder="Search memes..."
    value={query}
    onChange={(e) => onChange(e.target.value)}
    className="w-full p-3 border border-gray-300 rounded mb-4"
  />
);

export default SearchBar;
