import React, { useContext, useState, useEffect } from 'react';
import SearchBar from "./SearchBar"
import MemeGrid from "./MemeGrid"
import MemeDetails from "./MemeDetails"
import { fetchData } from '../services/api';
import { MemeContext, MemeProvider } from '../contexts/MemeContext';


const MemeCatalog = () => {
    const { memes, setMemes } = useContext(MemeContext);
    const [selectedMeme, setSelectedMeme] = useState(null);
  
    useEffect(() => {
      const getMemes = async () => {
        try {
          const data = await fetchData('submission', 'submission_id, attachment, title, text, redditor_id, created_at');
          setMemes(data);
        } catch (error) {
          console.error('Failed to fetch memes:', error);
        }
      };
  
      getMemes();
    }, [setMemes]);
  
    const handleMemeClick = (meme) => {
      setSelectedMeme(meme);
    };
  
    const handleClose = () => {
      setSelectedMeme(null);
    };
    
    return(
        <MemeProvider>
            <div className="flex-1 p-4 md:ml-64">
                <SearchBar />
                <MemeGrid memes={memes} onMemeClick={handleMemeClick} />
                {selectedMeme && (
                    <MemeDetails meme={selectedMeme} onClose={handleClose} />
                )}
            </div>
        </MemeProvider>
    );
};

export default MemeCatalog