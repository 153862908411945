import React from 'react';

const Introduction = () => (
  <section className="p-4 flex flex-wrap">
    <div className="w-full md:w-2/3">
      <h1 className="text-2xl font-semibold">Hi, my name is Sasha</h1>
      <p className="mt-2">
        I like <del>Sex, Drugs and Rock'n'Roll</del> <ins>Automation</ins>, <ins>Philosophy</ins> and <ins>Music</ins>
      </p>
      <p className="mt-2">
        I speak German<sup className="ml-1 text-xs">Native</sup>, English<sup className="ml-1 text-xs">C2</sup>, French<sup className="ml-1 text-xs">A2</sup> and am learning Arabic.
      </p>
      <p className="mt-2">
        I am most familiar with <mark className="bg-yellow-200">Python</mark> and <mark className="bg-yellow-200">JavaScript</mark>.
      </p>
      <p className="mt-2">
        If you want to explore my CV in 8bit, check out this <a href="./game.html" className="text-blue-500 hover:underline">GAME</a>.
      </p>
    </div>
    <div className="w-full md:w-1/3 flex justify-center items-center mt-4 md:mt-0">
      <img src="./ppic.png" className="w-48 h-48 transition-transform duration-300 transform hover:scale-150" alt="Profile" id="profile-photo" />
    </div>
  </section>
);

export default Introduction;
