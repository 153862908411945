import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContactInfo from './ContactInfo'; // Adjust the import path as needed

const Header = () => {
  const navigate = useNavigate();
  const [isContactInfoVisible, setContactInfoVisible] = useState(false);

  const handleNavigation = () => {
    navigate('/');
  };

  const toggleContactInfo = () => {
    setContactInfoVisible(!isContactInfoVisible);
  };

  return (
    <header className="fixed top-0 left-0 w-full p-4 bg-white shadow-md z-50 flex justify-between items-center">
      <h1 className="text-3xl font-bold cursor-pointer" onClick={handleNavigation}>
        Sasha Kaun
      </h1>
      <button className="md:hidden text-gray-800" onClick={toggleContactInfo} id="hamburger-menu">
        ☰
      </button>
      {isContactInfoVisible && (
        <div className="absolute right-4 top-12 w-64 bg-white shadow-lg rounded-lg p-4">
          <ContactInfo />
        </div>
      )}
    </header>
  );
};

export default Header;
