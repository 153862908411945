import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MemeProvider } from './contexts/MemeContext';
import Homepage from './Homepage';
import ContentNotAvailable from './components/ContentNotAvailable';
import Header from './components/Header';
import Navigation from './components/Navigation';
import MemeCatalog from './components/MemeCatalog';

const App = () => (
  <Router>
    <MemeProvider>
      <Header />
      <Navigation />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/not-available" element={<ContentNotAvailable />} />
        <Route path="/meme-catalogue" element={<MemeCatalog />} />
        {/* Add other routes as needed */}
        <Route path="*" element={<ContentNotAvailable />} /> {/* Fallback route for undefined paths */}
      </Routes>
    </MemeProvider>
  </Router>
);

export default App;
