// src/supabase.js
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = "https://uobbuprngcwwqrtdcgnw.supabase.co";
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvYmJ1cHJuZ2N3d3FydGRjZ253Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTg3MjAxNjUsImV4cCI6MjAzNDI5NjE2NX0.7vNvNd8Z25bKL6InrcDS95pSiwe610knlTVM-5Ycw4o";

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase URL or Key');
}

const supabase = createClient(supabaseUrl, supabaseKey);

export default supabase;