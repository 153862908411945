import React, { useState } from 'react';
import GameLink from './GameLink';
import ContactInfo from './ContactInfo'; // Adjust the import path as needed

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isContactInfoVisible, setContactInfoVisible] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleContactInfo = () => {
    setContactInfoVisible(!isContactInfoVisible);
  };

  return (
    <>
      {/* Top Navigation for Mobile */}
      <header className="fixed top-0 left-0 w-full p-4 bg-white shadow-md z-50 flex justify-between items-center md:hidden">
        <h1 className="text-3xl font-bold">Sasha Kaun</h1>
        <button onClick={toggleMenu} className="text-gray-800">
          ☰
        </button>
      </header>

      {/* Top Navigation Menu for Mobile */}
      <div className={`fixed top-16 left-0 w-full bg-white shadow-md z-40 ${isOpen ? 'block' : 'hidden'} md:hidden`}>
        <ul className="p-4">
          <li>
            <a href="cv.html" className="block text-blue-500 hover:underline">CV</a>
            <ul className="ml-4 mt-2">
              <li><GameLink /></li>
              <li><a href="chat.html" className="block text-blue-500 hover:underline">Chat Bot</a></li>
            </ul>
          </li>
          <li className="mt-2">
            <a href="diary.html" className="block text-blue-500 hover:underline">Diary</a>
          </li>
          <li className="mt-2">
            <a href="/not-available" className="block text-blue-500 hover:underline">AI</a>
            <ul className="ml-4 mt-2">
              <li><a href="/not-available" className="block text-blue-500 hover:underline">Language Cortex</a></li>
              <li><a href="/not-available" className="block text-blue-500 hover:underline">Visual Cortex</a></li>
              <li><a href="/not-available" className="block text-gray-400 cursor-not-allowed">AGI</a></li>
            </ul>
          </li>
          <li className="mt-2">
            <a href="/not-available" className="block text-blue-500 hover:underline">Simulation</a>
          </li>
          <li className="mt-2">
            <a href="/not-available" className="block text-blue-500 hover:underline">About</a>
          </li>
          <li className="mt-2">
            <a href="/not-available" className="block text-gray-400 cursor-not-allowed">Secrets</a>
          </li>
          <li className="mt-2">
            <button onClick={toggleContactInfo} className="block text-blue-500 hover:underline">Contact</button>
            {isContactInfoVisible && <ContactInfo />}
          </li>
        </ul>
      </div>

      {/* Sidebar Navigation for Desktop */}
      <aside className="hidden md:block fixed top-20 left-0 h-full w-64 p-4 bg-white shadow-md z-40">
        <h2 className="text-xl font-semibold mb-4">Navigation</h2>
        <ul>
          <li>
            <a href="./../cv.pdf" className="text-blue-500 hover:underline">CV.pdf</a>
            <ul className="ml-4 mt-2">
              <li><GameLink /></li>
              <li><a href="chat.html" className="text-blue-500 hover:underline">... or talk to M3</a></li>
            </ul>
          </li>
          <li className="mt-2">
            <a href="/meme-catalogue" className="text-blue-500 hover:underline">some M3M3s</a>
          </li>
          <li className="mt-2">
            <a href="/not-available" className="text-gray-400 cursor-not-allowed">Me virtually</a>
            <ul className="ml-4 mt-2">
              <li><a href="/not-available" className="text-gray-400 cursor-not-allowed">Language Cortex</a></li>
              <li><a href="/not-available" className="text-gray-400 cursor-not-allowed">Visual Cortex</a></li>
              <li><a href="/not-available" className="text-gray-400 cursor-not-allowed">Movement Centers</a></li>
            </ul>
          </li>
          <li className="mt-2">
            <a href="/not-available" className="text-gray-400 cursor-not-allowed">Simulation</a>
          </li>
          <li className="mt-2">
            <a href="/not-available" className="text-blue-500 hover:underline">About</a>
          </li>
          <li className="mt-2">
            <a href="/not-available" className="text-gray-400 cursor-not-allowed">Secrets</a>
          </li>
          <li className="mt-2">
            <button onClick={toggleContactInfo} className="text-blue-500 hover:underline">Contact</button>
            {isContactInfoVisible && <ContactInfo />}
          </li>
        </ul>
      </aside>
    </>
  );
};

export default Navigation;
