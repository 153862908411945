import React from 'react';

const MemeCard = ({ meme, onMemeClick }) => {
  return (
    <div
      className="meme-card bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 cursor-pointer"
      onClick={() => onMemeClick(meme)}
    >
      {meme.attachments.map((attachment, index) => (
        <img key={index} src={attachment.url} alt={attachment.format} className="w-full h-48 object-cover" />
      ))}
      <div className="p-4">
        <p className="text-gray-600">Click to view details</p>
      </div>
    </div>
  );
};

export default MemeCard;
