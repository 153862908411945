// services/api.js
import supabase from '../supabase';

export const fetchData = async (table, attributes, how_many=100) => {
  if (!table) throw new Error("Table name is required");
  if (!attributes) throw new Error("Attributes are required");

  const { data, error } = await supabase
    .from(table)
    .select(attributes)
    .range(0,how_many);

  if (error) {
    throw new Error(`Failed to fetch data from ${table}`);
  }

  const parsedData = data.map((item) => {
    let attachments = [];
    if (item.attachment) {
      try {
        const parsedAttachments = typeof item.attachment === 'string' ? JSON.parse(item.attachment) : item.attachment;
        if (typeof parsedAttachments === 'object' && parsedAttachments !== null) {
          attachments = Object.entries(parsedAttachments).map(([format, url]) => ({
            format,
            url,
          }));
        }
      } catch (e) {
        console.error('Failed to parse attachment:', e);
      }
    }
    return {
      ...item,
      attachments,
    };
  });

  return parsedData;
};
