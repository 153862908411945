import React from 'react';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Introduction from './components/Introduction';
import ContactInfo from './components/ContactInfo';

const Homepage = () => {
  
    return (
      <div>
        <Header />
        <Navigation />
        <div className="pt-16 md:flex">
          <main className="flex-1 p-4 md:ml-64">
            <Introduction />
            <ContactInfo />
          </main>
        </div>
      </div>
    );
  };
  
  const App = () => (
      <Homepage />
  );
  

export default App;
