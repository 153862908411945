// contexts/MemeContext.js
import React, { createContext, useState } from 'react';

export const MemeContext = createContext();

export const MemeProvider = ({ children }) => {
  const [memes, setMemes] = useState([]);

  return (
    <MemeContext.Provider value={{ memes, setMemes }}>
      {children}
    </MemeContext.Provider>
  );
};
